<template>
  <div class="service-manage-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item>
          <i class="icon-home"></i>打卡进店管理
        </Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="member-search">
        <Row>
          <Col span="24">
          <h2 class="member-search-title border-bottom">
            <span class="member-search-text">按条件搜索</span>
          </h2>
          </Col>
        </Row>
        <div class="search-form" @keyup.enter="handleSearch">
          <Form :label-width="90" :model="searchData">
            <Row :gutter="16" v-if="!branchShow">
              <Col span="6">
              <Form-item label="省份：">
                <Select clearable v-model="searchData.pid" placeholder="请选择" @on-change="changePro">
                  <Option :value="v.id" v-for="v in proList" :key="v.id">{{ v.name }}</Option>
                </Select>
              </Form-item>
              </Col>
              <Col span="6">
              <Form-item label="城市：">
                <Select clearable v-model="searchData.cid" placeholder="请选择" @on-change="changeCity"
                  @click.native="changeBossCity">
                  <Option :value="v.id" v-for="v in cityList" :key="v.id">{{ v.name }}</Option>
                </Select>
              </Form-item>
              </Col>
              <Col span="6">
              <Form-item label="门店：">
                <Select clearable v-model="searchData.sid" placeholder="请选择" @on-change="changeStore"
                  @click.native="changeBossBranch">
                  <Option :value="v.id" v-for="v in storeList" :key="v.id">{{ v.name }}</Option>
                </Select>
              </Form-item>
              </Col>
              <Col span="6">
              <Form-item label="顾客：">
                <Select clearable filterable placeholder="请选择" v-model="searchData.member_id">
                  <Option v-for="item in userList" :value="item.id" :key="item.id">{{ item.true_name }}</Option>
                </Select>
              </Form-item>
              </Col>
            </Row>
            <Row :gutter="16" v-else>
              <Col span="6">
              <Form-item label="省份：">
                <Input disabled v-model="proName"></Input>
              </Form-item>
              </Col>
              <Col span="6">
              <Form-item label="城市：">
                <Input disabled v-model="cityName"></Input>
              </Form-item>
              </Col>
              <Col span="6">
              <Form-item label="门店：">
                <Input disabled v-model="sName"></Input>
              </Form-item>
              </Col>
              <Col span="6">
              <Form-item label="顾客：">
                <Select clearable placeholder="请选择" v-model="searchData.member_id">
                  <Option v-for="item in userList" :value="item.id" :key="item.id">{{ item.true_name }}</Option>
                </Select>
              </Form-item>
              </Col>
            </Row>
            <Row :gutter="16">
              <Col span="6">
              <Form-item label="打卡地址：">
                <Select clearable v-model="searchData.dkdz" placeholder="请选择">
                  <Option :value="item" v-for="(item, index) in punchAddresses" :key="index">{{ item }}</Option>
                </Select>
              </Form-item>
              </Col>
              <Col span="6">
              <Form-item label="打卡日期：">
                <Date-picker @on-change="handleChangeDate" type="daterange" placeholder="选择日期"
                  style="width:100%;"></Date-picker>
              </Form-item>
              </Col>
              <Col span="6">
              <Form-item label="性别：">
                <Select clearable v-model="searchData.sex" placeholder="请选择">
                  <Option value="0">男</Option>
                  <Option value="1">女</Option>
                </Select>
              </Form-item>
              </Col>
              <Col span="6">
              <Form-item label="打卡名次：">
                <Input-number :min="1" v-model="searchData.mc_min"></Input-number>
                至
                <Input-number :min="searchData.mc_min" v-model="searchData.mc_max"></Input-number>
              </Form-item>
              </Col>
            </Row>
            <Row>
              <Col :lg="{ span: 12, offset: 12 }" :md="{ span: 12, offset: 12 }">
              <Button type="warning" @click="handleSearch">&nbsp;&nbsp;搜索&nbsp;&nbsp;</Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div class="content-box">
        <h2 class="content-title border-bottom"
        <span>打卡记录</span>
        <div class="member-oprate">
          <a href="javascript:;" class="export" @click="exportData()">导出</a>
        </div></h2>
        <div class="content-body">
          <Table :columns="columns" :data="resultData" ref="table"></Table>
          <Row>
            <Col span="12">
            <div class="table-oprate"></div>
            </Col>
            <Col span="12">
            <span class="records">共 {{ sizeTotal }} 条记录</span>
            </Col>
          </Row>
          <div class="page-nations-wrapper">
            <div class="page-nations">
              <Page :total="sizeTotal" :current="searchData.page" :page-size="searchData.size"
                @on-change="handleChangePage">
              </Page>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import memberService from '@/services/memberService';
import comparisonService from '@/services/comparisonService';
import punchService from '@/services/punchService';
import vTitle from 'components/topHeader/topHeader';

export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {
			branchShow: false,
			proList: [],
			cityList: [],
			storeList: [],
			userList: [],
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			searchData: {
				page: 1,
				size: 10,
				dkdz: '',
				sex: '',
				start_time: '',
				end_time: '',
				pid: '',
				cid: '',
				sid: '',
				member_id: '',
				mc_min: '',
				mc_max: '',
			},
			sizeTotal: 0,
			resultData: [],
			columns: [
				{ title: '归属门店', key: 'ssmd', align: 'center' },
				{ title: '姓名', key: 'xm', align: 'center' },
				{ title: '性别', key: 'xb', align: 'center' },
				{ title: '年龄', key: 'age', align: 'center' },
				{ title: '进店时间', key: 'jdsj', align: 'center' },
				{ title: '名次', key: 'mc', align: 'center' },
				{ title: '打卡地址', key: 'dkdz', align: 'center' },
				{ title: '照片', key: 'rq', align: 'center' },
				{ title: '定位', key: 'rq', align: 'center' },
			],
			punchAddresses: [],
		};
	},
	methods: {
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			if (this.searchData.sid) {
				const params = {
					page: 1,
					size: 1000,
					pid: this.searchData.pid,
					cid: this.searchData.cid,
					sid: this.searchData.sid,
				};
				comparisonService.getUserList(params).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		// 点击门店先选择城市
		changeBossBranch() {
			if (!this.searchData.cid) {
				this.$Message.warning('请先选择城市');
			}
		},
		// 点击城市先选择省份
		changeBossCity() {
			if (!this.searchData.pid) {
				this.$Message.warning('请先选择省份');
			}
		},
		initData() {
			const list = JSON.parse(localStorage.getItem('userInfo'));
			memberService.getBase().then((data) => {
				this.proList = data.branch.pro;
				this.city = data.branch.city;
				this.store = data.branch.store;
				this.power = list.user_info.power;
				this.memberTypeData = data.member_type;
				if (this.power.indexOf('服务管理--查看所属店面会员信息权限') === -1) {
					this.branchShow = false;
				} else {
					if (
						this.power.indexOf('服务管理--查看全国会员信息权限') === -1 &&
						this.power.indexOf('服务管理--查看所属区域会员信息权限') === -1
					) {
						this.branchShow = true;
						this.branch_id = list.user_info.branch;
						this.searchData.sid = this.branch_id[2];
						this.searchData.cid = this.branch_id[1];
						this.searchData.pid = this.branch_id[0];
						this.proName = list.user_info.pro_name;
						this.cityName = list.user_info.city_name;
						this.sName = list.user_info.branch_name;
						this.changeStore();
					}
				}
			});
		},
		getPunchAddresses() {
			punchService.getPunchAddresses().then((data) => {
				this.punchAddresses = data;
			});
		},
		getPunchList() {
			punchService.getPunchList(this.searchData).then((data) => {
				this.resultData = data.list;
				this.sizeTotal = data.row_size;
			});
		},
		handleChangeDate(date) {
			this.searchData.start_time = date[0];
			this.searchData.end_time = date[1];
		},
		handleChangePage(page) {
			this.searchData.page = page;
			this.getPunchList();
		},
		handleSearch() {
			this.searchData.page = 1;
			this.getPunchList();
		},
		exportData() {
			location.href =
				'http://conlife.sskh.net:9928/api/services/export_dkjl?dkdz=' +
				this.searchData.dkdz +
				'&sex=' +
				this.searchData.sex +
				'&start_time=' +
				this.searchData.start_time +
				'&end_time=' +
				this.searchData.end_time +
				'&member_id=' +
				this.searchData.member_id +
				'&pid=' +
				this.searchData.pid +
				'&cid=' +
				this.searchData.cid +
				'&sid=' +
				this.searchData.sid;
		},
	},
	mounted() {
		this.initData();
		this.getPunchAddresses();
		this.getPunchList();
	},
};
</script>

<style lang="less" scoped>
.member-search {
  background-color: #fff;
  margin-bottom: 20px;
}

.search-form {
  padding: 15px 20px;
}
.member-oprate {
  float: right;
  font-size: 14px;
}

.member-oprate .export {
  background: url(@/assets/img/icon_export.png) no-repeat;
}

</style>
